#Table-outer-container {
    /* margin: -440px 0px 80px 0px; */
    width: 100%;
    min-height: 80vh;
    margin-bottom: 80px;
}

#Table-container {
    height: 100%;

    min-height: 80vh;
    max-width: 1140px;
    margin: auto;
}

#Block-data-table {
    width: auto;
    margin: auto;
}

#Upper-data-table {
    width: auto;
    margin: auto;
    grid-template-rows: 1fr 1fr;
}

#Counter-outer-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto 60px auto;
    max-width: 950px;
}

#Counter-outer-container-mobile {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 60px;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    text-align: start !important;
}

#Search-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

#Search {
    width: fit-content;
    padding: 20px 0px 80px 0px;
}

#Search-InputBase {
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
}

#Search-input {
    font-size: 14px;
    padding: 20px 0px;
    width: 100%;
}

#Search-result {
    width: 85%;
    margin: 20px auto auto auto;
    transition: ease 1500ms;
}

#Search-table {
    width: 97%;
    transition: ease 1500ms;
    margin: 20px auto auto auto;
}

#Tabs-container {
    display: flex;
    width: 100%;
    display: -webkit-flex;
    margin: auto;
    max-width: 1140px;
    /* Safari */
}

.Tab {
    width: 100%;
    padding: 2px;
    flex: 1;
    cursor: pointer;
    min-width: 10px;
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
}

.Tab-icon-container {
    margin: auto;
    padding-bottom: 10px;
    flex: 1;
    width: max-content;
    min-width: 10px;
    height: 55px;
    box-shadow: 0 0 10px transparent;
    transition: box-shadow 0.4s;
}

.Tab-icon {
    width: 47px;

    min-width: 10px;
}

#Table-outer-container-mobile {
    margin: 120px 0px 50px 0px;
    height: 100%;
    width: 100%;
    min-height: 80vh;

}

#Table-container-mobile {
    height: auto;
    width: 100%;
    min-height: 80vh;
    max-width: 1140px;
}


#Search-mobile {
    width: 100%;
    padding: 12px 0px 20px 0px;
}

.Select-item-wrapper {
    display: grid;
    width: 100%;
}

.Select-item-container {
    width: 100%;
    display: flex !important;
    margin-bottom: 16px;
}

.Select-icon-container {
    width: fit-content;
    display: flex !important;
    padding: 0px 20px 0px 21px !important;
    min-width: 10px;
}

.Select-icon-selected {
    display: flex !important;
    justify-content: flex-end !important;
    margin: auto 20px auto auto !important;
}

.Select-value {
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #d3d3ef;
    font-size: 14px;
    text-align: center !important;
    align-items: center !important;
    display: flex;
}

#Select-divider {
    width: 100%;
    height: 1px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 1260px) {
    #Table-container {
        padding: 0px 30px;
    }

    #Block-data-table {
        max-width: 1140px;
    }

    #Upper-data-table {

        margin: auto auto auto auto;
        grid-template-rows: 1fr 1fr;
        max-width: 1140px
    }
}

@media only screen and (max-width: 900px) {
    .Tab-icon {
        width: 43px;
    }
}

@media only screen and (max-width: 800px) {
    .Tab-icon {
        width: 40px;
    }
}

@media only screen and (max-width: 600px) {
    #Block-data-table {
        height: 100%;

        max-width: 1040px;
        padding-bottom: 0px;
    }

    #Search {
        width: 100%;
        margin: auto;
    }

    .Tab-icon-container {
        padding-bottom: 0px !important;
    }
}

.Tab-bottom-line {
    margin: 20px 0px 20px 0px;
    width: 100%;
    height: 4px;
    flex: 1;
}

.Tab {
    width: 100%;
    padding: 2px;
    cursor: pointer;
    min-width: 10px;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.36 !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #a1a1ca !important;
    max-width: 100px !important;

    opacity: 0.2;
    transition: opacity 0.2s;
}

.Tab:hover {
    opacity: 1;
}

.Tab.Active {
    color: #fff !important;
    font-weight: bold !important;
    opacity: 1;
}

#Metamask-container {}

#AccountId_container {
    color: #fff !important;
    font-weight: bold !important;
    opacity: 1;
    font-size: 10px;
}