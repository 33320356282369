#Footer-outer-container {
    padding: 40px 30px 40px 30px;
    background-color: #161535;
}

#Footer-container {
    max-width: 1140px;
    margin: auto;
}

#Footer-icon-section {
    max-width: 1140px;
    margin: auto;
    justify-content: center;
    border-bottom: 1px solid #4f4f72;
}

#Footer-icon-wrapper {
    max-width: 1140px;
    margin: 40px 0px 35px 0px;
}

#Footer-icon-ul {
    list-style-type: none !important;
    margin-top: auto;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline-start: 0px !important;
}

#Footer-text {
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    width: 100%;
}

.Footer-a {
    margin: 0 auto;
    display: block;
    width: 52px;
    height: 52px;
    background: linear-gradient(to bottom, #8787AB, #4F4F72);
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 10px transparent;
    transition: box-shadow 0.4s;
    overflow: hidden;
}

.Footer-icon-img {
    width: 29px;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Footer-a:hover {
    box-shadow: 0 0 10px #7676a5;
    background: linear-gradient(to bottom, #C4C4ED, #626089);
}

#Footer-bottom-section {
    padding-top: 40px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 40px;
}

#Footer-bottom-section-contact-form-upper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 19px;
    height: 44px;
}

#Footer-bottom-section-contact-form-below {
    padding-top: 20px;
}

#Footer-bottom-section-trend {
    border-bottom: 1px solid #4f4f72;
    height: fit-content;
    padding-bottom: 25px;
}

#Button-footer-signup {
    background-image: linear-gradient(to bottom, #aeaed1, #7676a5) !important;
    width: 65px !important;
}

#Button-footer-signup:hover {
    box-shadow: 0 0 5px #AEAED1 !important;
}

#Footer-bottom-signup-container {
    height: 44px !important;
}

#Footer-bottom-signup-container {
    height: 44px !important;
}

#Footer-bottom-signup-text {
    font-size: 16px;
    color: #d3d3ef;
    font-family: "DINosaur Bk", "CheyenneRegular", sans-serif;
    text-align: initial;
    max-width: 260px;
}

#Footer-bottom-text {
    padding-top: 20px;

    font-family: "DINosaur Bk", "CheyenneRegular", sans-serif;
    font-size: 14px;
    color: #d3d3ef;
    text-align: left;
    /* padding-bottom: 80px; */
}

.Footer-bottom-grid-header {
    display: flex;
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    color: #fff;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    font-size: 16px;
}

.stripe-right {
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(to right, #07F6FE, #119DF3);
    margin-top: 15px;
}

.stripe-left {
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(to right, #07F6FE, #119DF3);
    margin-left: 0px;
    margin-bottom: 25px;
}

#Button-footer-container {
    text-align: justify;
    margin-bottom: 55px;
}

#Button-footer {
    width: 175px !important;
    font-size: 14px;
    font-weight: bold;
    line-height: 0.86;
    text-align: center;
    color: #fff;
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    padding: 15px 59px 17px 60px;
}

#Button-footer:hover {
    box-shadow: 0 0 10px #119df3 !important;
}

.border {
    box-shadow: inset 0 0 0 2px #8c8da4 !important;
}

#Footer-img-container {
    overflow: hidden !important;
}

/*Footer img Css */
#Footer-img {
    max-width: 100%;
    border-radius: 4px !important;
    transition: all 0.4s;
}

#Footer-img:hover {
    transform: scale(1.1);
    overflow: hidden !important;
}

#Footer-trend-text-header {
    margin: 10px 0 5px 0;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    text-align: left;
}

#Footer-trend-text-date {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.27;
    letter-spacing: 1.1px;
    color: #d3d3ef;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    text-align: left;
}

/*************** CSS For Mobile  ************/
#Footer-container-mobile {
    padding: 0 30px;
}

#Footer-icon-section-mobile {
    max-width: 1140px;
    margin: auto;
    justify-content: center;
    border-bottom: 1px solid #4f4f72;
}

@media only screen and (max-width: 1279px) {
    #Footer-outer-container {
        padding: 40px 0px;
        background-color: #161535;
    }
}

@media only screen and (max-width: 1024px) {
    #Footer-container {
        padding: 0 30px;
    }

    #Footer-icon-section {
        margin-bottom: 40px;
        border-bottom: 1px solid #4f4f72;
    }

    #Footer-text {
        font-size: 20px;
    }

    #Footer-icon-wrapper {
        margin: 5px auto 40px auto;
    }

    #Footer-icon-ul {
        max-width: 260px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding-inline-start: 0px !important;
        margin: auto;
    }

    #Footer-bottom-section {
        display: block;
        padding-top: 0px;
    }

    .Footer-bottom-grid-header {
        display: block;
        font-size: 20px;
        text-align: left;
        margin-bottom: 30px;
    }

    #Footer-bottom-text {
        font-size: 12px;
        padding-top: 20px;
        /* padding-bottom: 40px; */
    }

    #Footer-bottom-section-trend {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    #Footer-bottom-section-contact-form-upper {
        display: grid;
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr 1fr;
        grid-gap: 10px;
        height: auto;
    }

    #Footer-bottom-section-contact-form-below {
        padding-top: 10px;
    }

    #Button-footer {
        max-width: 100%;
        width: 100% !important;
    }

    #Footer-trend-text-header {
        margin: 20px 0;
    }

    #Footer-trend-text-date {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        color: #d3d3ef;
    }

    #Button-footer-signup {
        width: 100% !important;
        margin-top: 10px;
    }

    #Button-footer-container {
        margin-bottom: 0px;
    }

    #Footer-bottom-signup-text {
        font-size: 15px;
        margin-bottom: 40px;
        max-width: 100%;
    }

    .Footer-icon-img {
        width: 26px !important;
    }
}

.Footer-icon-li {
    height: fit-content;
    margin-top: 0px;
}

.Footer-icon-li-mobile {
    margin: 20px auto 0px auto;
    height: fit-content;
}