#Counter-container {
    text-align: center;
}

.stripe {
    margin: 20px auto 15px auto;
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background: linear-gradient(to right, #07F6FE, #119DF3);
}

.figure {
    font-family: "DINosaur Med", sans-serif !important;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: block;
    margin: auto;
}

.label {
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: auto;
}

@media only screen and (min-width: 1024px) {
    .stripe {
        margin: 20px auto 13px auto;
    }
}

@media only screen and (min-width:600px) {
    #Counter-container {
        min-width: 150px;
    }
}


/*COUNTER CSS FOR MOBILE */

@media only screen and (max-width: 475px) {
    #Counter-container {
        text-align: center;
    }

    .label {
        font-size: 15px;
        line-height: 1.2;
        margin-top: 0px;
        max-width: 100px;
    }

    .figure {
        margin-bottom: 0px;
        font-size: 26px;
        line-height: 1.33;
    }

    .stripe {
        margin: 18px auto 16px auto;
    }
}