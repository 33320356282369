.Txcard-container {
    margin: auto;
}

#Carousel {
    width: 100%;
    margin: auto;
    height: 100%;
}

.Carousel-inner {
    width: 100%;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
}

.Carousel-inner .Carousel-icon-container {
    height: inherit;
    align-self: center;
    cursor: pointer;
    max-width: 10px;
}

.Carousel-inner .Carousel-icon {
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto;
}

.Right-icon-img {
    transform: scaleX(-1);
}

.Carousel-inner .Center-container {
    padding: 0px 20px;
    width: -webkit-fill-available;
    width: 100%;
    height: 100%;
    margin: auto;
}

.Carousel-inner .Center {
    margin: 0 auto;
    width: inherit;
    height: 100%;
    /* display: grid; */
    grid-template-rows: 7fr 1fr 7fr;
    grid-gap: 15px;
    text-align: justify;
    text-align-last: center;
    flex-wrap: wrap;
    border-radius: 5px;
    transition: ease 1s;
}

.Slider-data-cell {
    padding-top: 5px;
}

.Carousel-inner .Center-mobile {
    margin: 0 auto;
    width: inherit;
    height: 100%;
    text-align: justify;
    text-align-last: center;
    flex-wrap: wrap;
    border-radius: 5px;
    transition: ease 1s;
}

.Slider-data-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
}

.Slider-data-table-mobile {
    padding-top: 2px;
    padding-bottom: 20px;
}

#Raw-Font {
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    width: 100% !important;
    text-align: center !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #a1a1ca !important;
    overflow: scroll;

}

.Slider-empty-data-table {
    padding: 15px 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #fff !important;
}

.Slider-empty-data-table-mobile {
    padding-top: 2px;
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #fff !important;
}

.Slider-data-cell-mobile {
    padding-top: 20px;
}

.Divider-mobile {
    margin-top: 17px;
    width: calc(100% + 66px);
    margin-left: -33px;
}

#Slider-header {
    padding: 20px 0px;

}

.Divider-desktop {
    align-self: center;
}

#RawContent-container {
    display: flex;
    justify-content: right;
    height: auto;
    position: absolute;
    right: 30px;
    width: auto !important;

}

#RawContent-button {
    font-family: "DINosaur Bd", "CheyenneBold" sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    width: fit-content;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

#TxCard-header {
    display: flex;
    justify-content: center;
}

#rawContent_container {
    margin-top: 10px;
    overflow: scroll;
    display: grid;
}

#TxId-container {
    cursor: pointer;
}

#TxId-tooltip {
    margin-top: 15px !important;
    padding: 5px 31px 8px !important;
    border-radius: 3px !important;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #d3d3ef !important;
}

#Info-tooltip {
    margin-top: 15px !important;
    padding: 5px 15px 8px !important;
    border-radius: 3px !important;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #d3d3ef !important;
}

#Info_icon {
    color: #a1a1ca;
    padding-left: 10px;
    max-width: 20px;
    cursor: pointer;
}

#Info_icon:hover {
    color: #fff;
}

@media only screen and (min-width: 1024px) {

    #rawContent_container {

        max-height: 78px;


    }

    #Raw-Font {

        max-height: 78px;

        overflow-wrap: anywhere;
    }
}

@media only screen and (max-width: 425px) {
    #RawContent-button {
        font-size: 12px;
    }

    #RawContent-container {
        right: 5px;
        top: 5px;
    }

    #Raw-Font {
        overflow-wrap: anywhere;
        max-height: 120px;
    }
}