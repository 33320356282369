#App {
  text-align: center;
  width: auto;
  min-height: 100vh;
  /* height: 100%!important; */
  font-family: "DINosaur", "CheyenneRegular", sans-serif;
  background-color: #20214d;
  background: #20214d;
  min-height: 100vh;
}

.App-header {
  /* height: 100%!important; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  overflow: scroll !important;
}

#App-container {
  /* height: 100%!important; */
  width: 100%;
  background-color: #20214d;
  overflow-y: scroll !important;
}

#Background {
  height: 440px;
  background-image: linear-gradient(to bottom, #211b40, #1b183d 51%, #20214d) !important;
}

.BoldBlueFont18 {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  text-align: center !important;
  font-size: 18px !important;
  font-weight: bolder !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #58bef2 !important;
}

.BoldFont16 {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #fff !important;
}

.BoldBlueFont16 {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #58bef2 !important;
}

[data-aos="fade-up-custom"] {
  transform: translate3d(0, 24px, 0) !important;
}

[data-aos="fade-up-custom"].aos-animate {
  transform: translate3d(0, 0, 0) !important;
}

.BoldFont16-dark {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #a1a1ca !important;
}

.BoldFont14 {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100%;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #a1a1ca !important;
}

.BoldFont14Hover {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100%;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #a1a1ca !important;
}

.BoldFont14Hover:hover {
  color: #58bef2 !important;
}

.BoldWhiteFont14 {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100%;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #fff !important;
}

.BoldBlueFont14 {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100%;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: rgb(0, 195, 255) !important;
}

.BoldRedFont14 {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100%;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #9b0054 !important;
}

.BlueFont14 {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100%;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: rgb(0, 195, 255) !important;
}

.BoldFont18 {
  width: 100% !important;
  text-align: center !important;
  font-size: 18px !important;
  color: #ffffff !important;
  padding-top: 2px !important;
}

.DarkBoldFont16 {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100% !important;
  text-align: center !important;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #a1a1ca;
  padding-top: 2px !important;
}

.DarkThinFont10 {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100% !important;
  text-align: center !important;
  font-size: 10px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #a1a1ca;
  padding-top: 2px !important;
}

.DisplayJsonRaw {
  word-break: break-all;
  overflow-wrap: anywhere;
  text-align-last: left;
  white-space: pre-wrap;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img#Block-icon {
  -webkit-filter: drop-shadow(0 4px 6px rgba(27, 25, 63, 0.4));
  filter: drop-shadow(0 4px 6px rgba(27, 25, 63, 0.4));
}

#butter-bar {
  width: 100%;
  background-color: #ff7442;
  min-height: 30px;
  display: flex;
  justify-content: center;
  z-index: 9;
  position: fixed;
}
