.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  color: white;
  text-align: left;
}

.info h1 {
  font-size: 30px;
  font-weight: bold;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  margin: 0;
}

.info span {
  font-size: 16px;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  margin: 0;
}

.info .external {
  font-size: 14px;
  color: #ececec;
}

.info .tokenInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info .attribute {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #20214d3f;
  border: 2px solid #20214d;
}

.info .attribute .name {
  font-size: 12px;
  font-weight: bold;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}

.info .attribute .value {
  font-size: 14px;
  font-weight: bold;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  margin: 0;
  color: rgba(255, 255, 255);
}

.invalid {
  color: rgb(248, 66, 66);
  font-size: 20px;
  font-weight: bold;
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
}
