#container {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
}

#gradient_box {
    width: fit-content;
    max-height: 200px;
    overflow-y: auto;
    background-image:
        linear-gradient(to bottom, #353763, #272852) !important;
    border-radius: 6px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

#list_item {
    padding: 8px 16px;
    cursor: pointer;
}

#list_item:hover {
    background-color: #20214d;
}

#list_item p {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

@media only screen and (max-width: 600px) {
    #gradient_box {
        margin: 0px 20px;
        max-height: 300px;
    }

    #list_item p {

        overflow-wrap: anywhere;
    }
}