#Card-container {
    padding: 0px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-indent: 0px !important;
}

#Card-container-mobile {
    padding: 0px 0px;
    width: 100%;
    text-indent: 0px !important;
}

#BlockCard-container {
    width: 100%;
}

#Inner-container {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 5fr 9fr;
    grid-gap: 20px;
}

#Inner-container-mobile {
    width: 100%;
    margin: auto;
}

#TxCards-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
}

#TxCard-row {
    margin: auto;
}

#Block-header-container {
    width: fit-content;
    margin: auto;
    padding-bottom: 10px;
}

#Block-header-icon {
    width: fit-content;
    margin: 0px auto 5px auto;
    display: flex;
}

#Block-icon {
    width: 47px;
}

#Block-header {
    width: 100%;
    display: flex;
    color: #fff;
    margin: auto;
}

#Header-time {
    width: 100%;
    display: flex;
}

#Coinweb-data-container {
    width: 100%;
    text-align: center !important;
    padding: 15px 0px 17px 0px;
}

#Coinweb-data {
    width: fit-content;
    margin: auto;
    text-align: center !important;
}

#Data-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

#Block-card-data-table {
    margin: auto;
    width: 100%
}

.Block-card-table-cell {
    margin-top: 20px;
}

#Button-mobile-container {
    display: flex !important;
    justify-content: center;
}

#Blockhash-icon-container-mobile {
    width: 100%;
    display: flex;
    margin: 30px auto 20px auto;
}

