#Graph-outer-container {
    display: flex;
    margin: auto;
    max-width: 1140px;
}

#Graph-container {
    max-width: 1140px;
    margin: auto;
}

.custom-tooltip {
    background-color: #4f4f72;
    padding: 5px 20px;
    border-radius: 6px;
}

.graph_tooltip_label {
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: auto;
}

#graph_label {
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #d3d3ef;
}

#Button_group_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    max-width: 1140px;
    margin: auto;
}