.Table-row-icon-container {
    display: grid;
}

.Row-container {
    display: grid;
}

.grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.grid_cell {
    margin: 5px 0px;
}

.Down-icon-img {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.Blockchain-cell-container {
    display: flex;
    align-items: center;
}

.Blockchain-icon-container {
    padding-right: 15px;
}

.scrollable-content::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
    background: #32345c;
    /* Set the background color of the scrollbar */
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: #a1a1ca;
    /* Set the color of the scrollbar thumb */
    border-radius: 10px;
    /* Optional: make the scrollbar thumb rounded */
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: #4f4f72 !important;
    color: #4f4f72 !important;
}

.Row-container:hover {
    background-color: #292a54;
}

.Table-Header {
    color: #d3d3ef !important;
    padding-bottom: px !important;
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: #fff !important;
    border-bottom: 1px solid #4f4f72 !important;
}

.Tab-tooltip {
    margin-top: 25px !important;
    padding: 5px 31px 8px !important;
    border-radius: 3px !important;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #d3d3ef !important;
}

/* headerrr*/
#Block-Data-Table-Mui {
    background-color: transparent !important;
    color: #4f4f72 !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: none !important;
    padding: 0px !important;
    margin: auto;
    position: relative !important;
}

.cs .Table-row:hover {
    background-color: #292a54;
}

.Tool-hash-container {
    padding: 5px 31px 8px;
    display: flex;
    border-radius: 3px;
    font-family: "DINosaur Med", "CheyenneMed", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #d3d3ef;
}

.Tooltip-hash-container {
    width: fit-content;
}

.Tool-hash-copy {
    color: transparent;
    cursor: pointer;
}

#collapse_container {
    overflow-wrap: anywhere;
    width: 100%;
    max-height: 50vh;
    overflow: auto;
    border: 1px solid #a1a1ca;
    padding: 10px;
    background-color: #20214a;
    border-radius: 6px;
    margin-right: 10px;
    box-sizing: border-box;
}

#code {
    white-space: pre;
    text-align: start;
    overflow: visible;
    margin: 0;
}


#Error-container {
    padding: 100px 0px 0px 0px;
}

#Error-icon-container {
    padding: 20px;
}

#Error-message-container {
    color: #d3d3ef;
    font-size: 14px;
    font-family: "DINosaur Bk"
}

#Loadmore-container {
    width: 100%;
    height: auto;
    margin-top: 25px;
    text-align: center;
}

#Loadmore-button {
    font-family: "DINosaur Bd", "CheyenneBold", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #067dfb;
    width: fit-content;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.Error-text {
    padding: 3px;
    font-family: "DINosaur-Book", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.4px;
}

#Wrap {
    cursor: pointer !important;
}

/*** MOBILE ***/
#Error-container-mobile {
    padding: 157px 0px 0px 0px;
}

@media only screen and (max-width: 600px) {
    .grid_container {
        display: block !important;
    }
}