.L1TxExplorerButton {
  font-family: "DINosaur Med", "CheyenneMed", sans-serif !important;
  width: 100%;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #a1a1ca !important;
  text-transform: none !important;
}
.L1TxExplorerButton img {
  height: 1em;
  vertical-align: middle;
  margin-right: 8px;
}

.L2txLogCard-container {
  margin: auto;
}

.L2TxLogInfo-container {
  width: 100%;
  margin: auto;
  height: 100%;
}

.LogContent-inner {
  width: 100%;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.LogContent-inner .LogContent-icon-container {
  height: inherit;
  align-self: center;
  cursor: pointer;
  max-width: 10px;
}

.LogContent-inner .LogContent-icon {
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
}

.LogContent-inner .Center-container {
  /* padding: 0px 20px 20px 20px; */
  width: -webkit-fill-available;
  /* width: 100%; */
  height: 100%;
  margin: auto;
}

.LogContent-inner .Center {
  margin: 0 auto;
  width: inherit;
  height: 100%;
  /* display: grid; */
  grid-template-rows: 7fr 1fr 7fr;
  grid-gap: 15px;
  text-align: justify;
  text-align-last: center;
  flex-wrap: wrap;
  border-radius: 5px;
  transition: ease 1s;
}

.L2TxLogCard-data-cell {
  padding-top: 5px;
}

.LogContent-inner .Center-mobile {
  margin: 0 auto;
  width: inherit;
  height: 100%;
  text-align: justify;
  text-align-last: center;
  flex-wrap: wrap;
  border-radius: 5px;
  transition: ease 1s;
}

.L2TxLogCard-data-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* display: flex;
    justify-content: space-around; */
}

.L2TxLogCard-data-table-mobile {
  padding-top: 2px;
}

#Raw-Font {
  font-family: "DINosaur Med", \"CheyenneMed\", sans-serif !important;
  width: 100% !important;
  text-align: center !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #a1a1ca !important;
  overflow: scroll;
}

#ParentsCarousel {
  width: 100%;
  margin: auto;
  height: 100%;
}

.ParentsCarousel-inner {
  width: 100%;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}

.ParentsCarousel-inner .ParentsCarousel-icon-container {
  height: inherit;
  align-self: center;
  cursor: pointer;
  max-width: 10px;
  padding-top: 10px;
}

.ParentsCarousel-inner .ParentsCarousel-icon {
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto;
}

.Right-icon-img {
  transform: scaleX(-1);
}

.ParentsCarousel-inner .ParentsCenter-container {
  padding: 0px 20px;
  width: -webkit-fill-available;
  width: 100%;
  height: 100%;
  margin: auto;
}

.ParentsCarousel-inner .ParentsCenter {
  margin: 0 auto;
  width: inherit;
  height: 100%;
  text-align: justify;
  text-align-last: center;
  flex-wrap: wrap;
  border-radius: 5px;
  transition: ease 1s;
  display: flex;
  justify-content: space-around;
}

.ParentsSlider-data-cell {
  padding-top: 5px;
}

.ParentsCarousel-inner .ParentsCenter-mobile {
  margin: 0 auto;
  width: inherit;
  height: 100%;
  text-align: justify;
  text-align-last: center;
  flex-wrap: wrap;
  border-radius: 5px;
  transition: ease 1s;
}

.ParentsSlider-data-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ParentsSlider-data-table-mobile {
  padding-bottom: 20px;
}

.ParentsSlider-data-cell-mobile {
  padding-top: 20px;
}

.Divider-mobile {
  margin-top: 17px;
  width: calc(100% + 66px);
  margin-left: -33px;
}

#ParentsSlider-header {
  padding: 20px 0px;
}

#Contract-header {
  display: flex;
}

#RawTxLog-container {
  cursor: pointer;
}

.TxLogFontMed14 {
  font-family: "DINosaur Med", \"CheyenneMed\", sans-serif !important;
  width: 100% !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #a1a1ca !important;
  overflow-wrap: anywhere;
}

.TxLogFontBold14 {
  font-family: "DINosaur Bd", "CheyenneBold", sans-serif !important;
  width: 100% !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #a1a1ca !important;
  overflow-wrap: anywhere;
}

.L2TxId-container {
  cursor: pointer;
  width: min-content !important;
}

.L2TxId-row {
  display: flex;
  margin: auto;
  width: fit-content;
}

.relativeCon {
  position: relative;
}

.absoluteRightText {
  display: flex;
  justify-content: right;
  height: auto;
  position: absolute;
  right: 0px;
  width: auto !important;
}

.linkArrow {
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.linkHover:hover {
  color: rgb(6, 125, 251) !important;
  cursor: pointer;
}

.gridRightBorder {
  border-right-color: #a1a1ca;
  border-right-style: groove;
  padding-right: 10px;
}

.operationCollapseCell {
  display: flex !important;
  /* width: 100%; */
  align-items: center;
  border-bottom: none !important;
  cursor: pointer;
  justify-content: center;
  padding: 0px !important;
  width: fit-content;
  margin: auto;
}

.callOpContainer {
  width: 100%;
}
