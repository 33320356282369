.buttonLoad {
  margin-left: 5px;
}

.buttonLoadDis {
  margin-left: 5px;
  color: #6d6d6d;
  border: 3px solid #0060df;
  border-radius: 3px 3px 3px 3px;
  background-color: #7b7b98;
}

.myPre {
  height: 100%;
  overflow: scroll;
  cursor: pointer;
}

.myPre:hover {
  color: blue;
}