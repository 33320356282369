.headerBody {
    overflow-wrap: break-word;
    height: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;
    right: 0;
    margin-right: 20px;
    align-items: normal;
}

.copyBox {
    height: 100%;
    align-self: normal;
}

.copyButton {
    color: #067dfb;
    font-size: 16px;
    background: #2d2e54;
    border-radius: 6px;
    border: none;
    padding: 0px 10px 10px 10px;
}